<template>
  <teleport to="body">
    <div class="modal modal__post-view grid">
      <!-- Close -->
      <button class="modal__close btn outline sm grey pl-10 pr-10" @click.prevent="closeAction">
        <BaseIcon class="ic-20 black" icon="close" />
      </button>

      <!-- Post -->
      <div v-if="item" class="modal__content" v-click-outside="closeAction">
        <ItemPost :item="item" isCommentsFull :isLoadComments="isLoadComments" @updateComments="updateComments" />
      </div>

      <!-- Load -->
      <BaseLoad v-else />
    </div>
  </teleport>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { ref } from 'vue'
import { BaseIcon, BaseLoad, ItemPost } from '@/components'
import posts from '@/api/modules/posts'
import comments from '@/api/modules/comments'

// Data
const route = useRoute()
const router = useRouter()
const isLoad = ref(false)
const isLoadComments = ref(false)
const item = ref(null)

// Created
getPost()

// Methods
async function getPost() {
  isLoad.value = true
  try {
    // Get post item
    await posts.setCounter(route.params.postId)
    const { data } = await posts.get(route.params.postId)
    const el = data.data
    item.value = {
      ...el,
      comments: []
    }

    // Get comments list
    isLoadComments.value = true
    const commentsList = await comments.getAll(route.params.postId)
    item.value.comments = commentsList.data.data
    isLoadComments.value = false
  } catch (error) {
    console.log(error)
  } finally {
    isLoad.value = false
  }
}
async function updateComments() {
  isLoadComments.value = true
  try {
    const commentsList = await comments.getAll(route.params.postId)
    item.value.comments = commentsList.data.data
  } catch (error) {
    console.log(error)
  } finally {
    isLoadComments.value = false
  }
}
function closeAction() {
  item.value = null
  router.back()
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;
  padding: 0;
  z-index: 1000;
  backdrop-filter: blur(5px);

  &::before,
  &::after {
    content: '';
    display: block;
    width: 1px;
    height: 20px;
  }

  &__content {
    width: 700px;
    margin: auto;
  }

  &__close {
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 2;
  }
}

@media screen and (max-width: 800px) {
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: auto;
    padding: 0;
    z-index: 1000;
    backdrop-filter: blur(5px);

    &::before,
    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 20px;
    }

    &__content {
      width: calc(100% - 20px);
      margin: auto;
      max-height: 90vh;
    }

    &__close {
      position: fixed;
      right: 10px;
      top: 10px;
      z-index: 2;
    }
  }
}
</style>
